import React from "react"
const Triangle = () => {
  return (
    <img
      src="/servicesPage/triangle.svg"
      alt="triangle"
      style={{ width: "4rem", height: "4rem", paddingBottom: ".5rem" }}
    />
  )
}
function ProductStrategy() {
  return (
    <img
      src="/servicesPage/prod.svg"
      alt="production"
      style={{ width: "100px", height: "100px" }}
    />
  )
}

function ProductDesign() {
  return (
    <img
      src="/servicesPage/product-design.svg"
      alt="product-design"
      style={{ width: "100px", height: "100px" }}
    />
  )
}
function CustomSoftware() {
  return (
    <img
      src="/servicesPage/custom-software.svg"
      alt="custom-software"
      style={{ width: "100px", height: "100px" }}
    />
  )
}
function DataEng() {
  return (
    <img
      src="/servicesPage/data-eng.svg"
      alt="data-eng"
      style={{ width: "100px", height: "100px" }}
    />
  )
}
function Arrow() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/arrow-services.svg"
      alt="arrow-icon"
    />
  )
}
function Eclipse() {
  return <img loading="lazy" src="/servicesPage/eclipse.svg" alt="eclipse" />
}
function PriceServices1() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/price-img1.svg"
      alt="price-service-one"
    />
  )
}

function PriceServices2() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/price-img2.svg"
      alt="price-service-two"
    />
  )
}
function PriceServices3() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/price-img3.svg"
      alt="price-service-three"
    />
  )
}
function PriceServices4() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/price-img4.svg"
      alt="price-service-four"
    />
  )
}
function DropdownArrow() {
  return (
    <img loading="lazy" src="/dropdown-arrow.svg" alt="price-service-five" />
  )
}
function DropdownArrowWhite() {
  return (
    <img
      src="/arrow-white.svg"
      style={{ width: "13px", height: "13px" }}
      alt="drop-down-icon"
    ></img>
  )
}
function CheckedIcon({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={fill || "white"} />
    </svg>
  )
}
function DotIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="7" r="6.5" fill="#343434" />
    </svg>
  )
}

export function TechnicalComplexityIcon() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/cardsIcon/img-1.png"
      alt="card-icon-1"
    />
  )
}
export function ThirdPartyIcon() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/cardsIcon/img-2.png"
      alt="technical-complexity"
    />
  )
}
export function FeatureFunctionalityIcon() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/cardsIcon/img-3.png"
      alt="feature-functionality"
    />
  )
}
export function CustomDesignIcon() {
  return (
    <img
      loading="lazy"
      src="/servicesPage/cardsIcon/img-4.png"
      alt="custom-design"
    />
  )
}

export {
  Eclipse,
  ProductStrategy,
  PriceServices3,
  Arrow,
  ProductDesign,
  CustomSoftware,
  DataEng,
  PriceServices2,
  PriceServices4,
  PriceServices1,
  DropdownArrow,
  DropdownArrowWhite,
  Triangle,
  CheckedIcon,
  DotIcon,
}
