export const images = {
  homepage: {
    HeroHomeImg: "./Presenting analytics.png",
    HeroUnderline: "./homepage/hero-text-underline.png",
    ClientTrackhero: "./homepage/client-trckhero.png",
    ClientOhmconnect: "./homepage/client-ohmconnect.png",
    ClientStrada: "./homepage/client-strada.png",
    ClientPixeleto: "./homepage/client-pixeleto.png",
    ClientVectorScient: "./homepage/client-vector-saint.png",
    ClientDriverbee: "./homepage/client-driver-bee.png",
    ClientAimExpert: "./homepage/client-aim-expert.png",
    ClientIbd: "./homepage/client-ibd.png",
    ClientHero: "./homepage/client-hero.png",
    ClientUgami: "./homepage/client-ugami.png",
    ClientRating: "./homepage/client-rating.png",
    ClientEnergyAction: "./homepage/client-energy-action.png",
    ProductServiceImg: "./services-product-strategy.png",
    UIUXServiceImg: "./services-ui-ux.png",
    CustomSoftwareServiceImg: "./services-software-development.png",
    DataEngineeringServiceImg: "./services-data-engineering.png",
    TransparentIcon: "./transparent.png",
    SimpleIcon: "./simple.png",
    ExpertsIcon: "./experts.png",
    FlexibleIcon: "./flexible.png",
    ContactIcon: "./contact-icon.png",
    AnalysisIcon: "./Analysis-icon.png",
    ProposalIcon: "./propsal-icon.png",
    TeamIcon: "./team-icon.png",
    StartIcon: "./start-icon.png",
    OhmConnectLogo: "./ohmconnect-logo.png",
    OhmconnectClient: "./ohmconnect-client.png",
    StradaImg: "./strada-img.png",
    StradaLogo: "./strada-logo.png",
    TrackheroImg: "./trackhero-img.png",
    TrackheroLogo: "./trachero-logo.png",
    EnergyActionLogo: "./energy-action-logo.png",
    EnergyActionImg: "./energy-action-img.png",
  },
  portfolio: {
    PortfolioAccretioImg: "./portfolio-accretio-img.png",
    PortfolioAccretioBg: "/portfolio-accretio-bg.png",
    PortfolioUgamiImg: "./portfolio-ugami-img.png",
    PortfolioUgamiBg: "/portfolio-ugami-bg.png",
    PortfolioDriverBg: "/portfolio-driver-bg.png",
    PortfolioDriverImg: "./portfolio-driver-img.png",
    PortfolioCowokifyBg: "/portfolio-coworkify-bg.png",
    PortfolioCowokifyImg: "./portfolio-coworkify-img.png",
    PortfolioKillBg: "/portfolio-killexpert-bg.png",
    PortfolioKillImg: "./portfolio-killexpert-img.png",
    PortfolioIbdBg: "/portfolio-ibd-bg.png",
    PortfolioIbdImg: "./portfolio-ibd-img.png",
    PortfolioRatingBg: "/portfolio-rating-bg.png",
    PortfolioRatingImg: "./portfolio-rating-img.png",
    PortfolioPixeletoBg: "/portfolio-pixeleto-bg.png",
    PortfolioPixeletoImg: "./portfolio-pixeleto-img.png",
    PortfolioAimBg: "/portfolio-aim-bg.png",
    PortfolioAimImg: "./portfolio-aim-img.png",
    PortfolioCommetBg: "/portfolio-commet-bg.png",
    PortfolioCommetImg: "./portfolio-commet-img.png",
    PortfolioTrackheroBg: "/portfolio-trackhero-bg.png",
    PortfolioTrackheroImg: "./portfolio-trackhero-img.png",
    PortfolioStradaBg: "/portfolio-strada-bg.png",
    PortfolioStradaImg: "./portfolio-strada-img.png",
    PortfoilioPredictBg: "/portfolio-predict-bg.png",
    PortfoilioPredictImg: "./portfolio-predict-img.png",
    PortfolioOhmBg: "/portfolio-ohm-bg.png",
    PortfolioOhmImg: "./portfolio-ohm-img.png",
    PortfolioHero: "/portfolio-hero.svg",
  },
  services: {
    ServicesHero: "/hero-services.svg",
    ServicesProductStrategy: "/servicesPage/services-product-strategy.png",
    ServicesUiUxDesign: "/services-ui-ux-design.png",
    ServicesDiscoveryWorkshop: "/services-discovery-workshop.png",
    ServicesSprintZero: "/services-sprint-zero.png",
    ServicesDesignSprint: "/services-design-sprint.png",
    ServicesUserResearch: "/services-user-research.png",
    ServicesUiUx: "/services-ui-ux.png",
    ServicesUsibilityTesting: "/services-usibility-testing.png",
    ServicesCustomDevelopment: "/services-custom-development.png",
    ServicesBendDev: "/services-bend-fend-dev.png",
    ServicesWebApp: "/services-web-mob-app.png",
    ServicesDevops: "/services-devops.png",
    ServicesQaTesting: "/services-qa-testing.png",
    ServicesDataEngineering: "/services-data-engeenring.png",
    ServicesDataAnalytics: "/services-data-analysis.png",
    ServicesDataVisuilization: "//services-data-visulization.png",
    ServicesAiMl: "/services-ai-ml.png",
    ServicesEtl: "/services-etl.png",
    ServicesTeamAugmentation: "/services-team-augmentation.png",
    ServicesProjectBased: "/services-team-based.png",
    ServicesTimeMaterial: "/services-time-material.png",
  },
}
