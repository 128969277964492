import React, { useEffect } from "react"
import Layout from "../../components/Layout"
 
import TrackHeroPage from "../../components/TrackHero"
export default function TrackHero() {
  
  

  return (
    <>
      <Layout>
        <TrackHeroPage />
      </Layout>
      
    </>
  )
}
