import React from "react"
import TalkToUs from "../shared/TalkToUsDark"
function Estimate() {
  return (
    <TalkToUs
      header="Let’s choose the best technology for your business"
      text="Already know what tech stack you want to use in your next app? Great! Still not quite sure? Let us advise you on the best solution for your unique case."
      btnText="Talk to US"
    />
  )
}

export default Estimate
