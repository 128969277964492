import React from "react"
import Heading from "../Heading"
import "./Talk.scss"
import { Box } from "@mui/material"
import Button from "../Button"
import { Triangle } from "../../Services-Page/SVGs"
function TalkToUs({ header, text, btnText }) {
  return (
    <div className="talk-to-us-dark">
      <div className="triangle"></div>
      <div className="inner-div">
        <Triangle />
      </div>

      <div
        className="container"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1000"
      >
        <div className="">
          <Heading variant="40" className="header">
            {header}
          </Heading>
        </div>

        <p className="text">{text}</p>
        <Box className="btn-box">
          <Button
            href="/contact"
            clr="white"
            text={btnText}
            border="#181930"
            variant="fill"
            // className="nav-btn"
          />
        </Box>
      </div>
    </div>
  )
}

export default TalkToUs
